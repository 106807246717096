import { gql } from '@apollo/client/core';
import { useMutation } from '@vue/apollo-composable';
import { useNotification } from '@/modules/core';
import { RECONCILIATIONS_QUERY } from '@/modules/reconciliation/compositions/reconciliations';

export function useCloseReconciliation(variables) {
  const { error } = useNotification();

  const { mutate, onDone, onError } = useMutation(CLOSE_RECONCILIATION_MUTATION, {
    update: (cache, { data: { closeReconciliation: closedReconciliation } }) => {
      const { reconciliations } = cache.readQuery({
        query: RECONCILIATIONS_QUERY,
        variables: variables.value,
      });

      cache.writeQuery({
        query: RECONCILIATIONS_QUERY,
        variables: variables.value,
        data: {
          reconciliations: {
            nodes: reconciliations.nodes.filter(({ id }) => id !== closedReconciliation.id),
            __typename: 'ReconciliationConnection',
          },
        },
      });
    },
  });

  onError((err) => {
    console.error('useCloseReconciliation', err);
    error();
  });

  return {
    mutate,
    onDone,
  };
}

const CLOSE_RECONCILIATION_MUTATION = gql`
  mutation closeReconciliation($id: ID!) {
    closeReconciliation(id: $id) {
      id
      closed
    }
  }
`;
