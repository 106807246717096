import { DateTime } from 'luxon';
import { isEmpty } from 'ramda';

import { PAYMENT_STATUS } from '@/modules/payment/types';

export const filterMissingEventsByDateAndSupplier = (missingEvents, rehandleParentDocuments, supplierId) =>
  missingEvents
    .filter((missingEvent) => !supplierId || missingEvent.supplierId === supplierId)
    .map((missingEvent) => {
      const newMissingEvent = { ...missingEvent };

      if (missingEvent.estimatedDate) newMissingEvent.estimatedDate = missingEvent.estimatedDate;

      if (missingEvent.parentDocumentId) {
        newMissingEvent.parentDocument = rehandleParentDocuments.find(
          (document) => document.id === missingEvent.parentDocumentId
        );
      }

      return newMissingEvent;
    });

export const mapImbalanceWithBalanceAndDueDateTerm = (reconciliations, payables, balancePayments, suppliers, terms) => {
  const supplierById = suppliers.reduce((suppliersMap, supplier) => {
    suppliersMap[supplier.id] = supplier;
    return suppliersMap;
  }, {});

  const payablesBalanceBySupplier = payables.reduce((payablesMap, { supplierId, balance }) => {
    payablesMap[supplierId] = balance;
    return payablesMap;
  }, {});

  const balancePaymentsBySupplier = balancePayments.reduce((balanceMap, { supplierId, id }) => {
    balanceMap[supplierId] = id;
    return balanceMap;
  }, {});

  const termsBySupplier = terms.reduce((termsMap, term) => {
    termsMap[term.supplierId] = term;
    return termsMap;
  }, {});

  const enrichedReconciliations = reconciliations.map(
    enrichReconciliation(supplierById, payablesBalanceBySupplier, balancePaymentsBySupplier, termsBySupplier)
  );

  const reconciliationsBySupplier = createReconciliationsBySupplier(enrichedReconciliations);

  return enrichedReconciliations.map((reconciliation) => {
    return {
      ...reconciliation,
      overdue: calcOverdue(reconciliationsBySupplier, reconciliation.supplierId, reconciliation.id),
    };
  });
};

const enrichReconciliation =
  (supplierById, payablesBalanceBySupplier, balancePaymentsBySupplier, termsBySupplier) => (reconciliation) => {
    const { paymentDueDate, supplierId, billedAmounts, paidAmounts } = reconciliation;

    return {
      ...reconciliation,
      paymentTerm: termsBySupplier[supplierId],
      paymentStatuses: getPaymentStatuses(termsBySupplier[supplierId], paymentDueDate),
      totalBilledAmount: !billedAmounts.length && !paidAmounts ? '-' : Number(sumAmounts(billedAmounts).toFixed(2)),
      totalPaidAmount: !billedAmounts.length && !paidAmounts ? '-' : Number(sumAmounts(paidAmounts).toFixed(2)),
      payablesBalance: payablesBalanceBySupplier[supplierId],
      hasInitialBalance: balancePaymentsBySupplier[supplierId],
      supplier: supplierById[supplierId],
      billingsLeftToBePaid: getUnpaidBillings(billedAmounts, paidAmounts),
    };
  };

const getUnpaidBillings = (billedAmounts, paidAmounts) => {
  const paidAmountsByBillingId = paidAmounts.reduce((paidMap, paidAmount) => {
    if (paidMap[paidAmount.billingId]) paidMap[paidAmount.billingId].push(paidAmount);
    else paidMap[paidAmount.billingId] = [paidAmount];
    return paidMap;
  }, {});

  return billedAmounts.reduce(
    (billingsToPay, billedAmount) => {
      const totalPaidAmount =
        paidAmountsByBillingId[billedAmount.billingId]?.reduce((sum, paidAmount) => sum + paidAmount.amount, 0) ?? 0;

      const leftToBePaid = Math.abs(billedAmount.amount) - Math.abs(totalPaidAmount); // could be credit

      if (leftToBePaid > 0)
        billingsToPay.push({
          ...billedAmount,
          balanceDue: leftToBePaid * Math.sign(billedAmount.amount), // if credit should be negative
        });
      return billingsToPay;
    },

    []
  );
};

const getPaymentStatuses = (paymentTerm, paymentDueDate) => {
  const paymentStatuses = [];
  if (paymentTerm && Number.isInteger(paymentTerm.paymentDueNet)) {
    const currentDate = DateTime.local();
    const dueDate = DateTime.fromISO(paymentDueDate).toUTC();
    if (currentDate > dueDate) paymentStatuses.push(PAYMENT_STATUS.OVERDUE);
    else if (currentDate >= dueDate.minus({ days: 7 })) paymentStatuses.push(PAYMENT_STATUS.APPROACHING_DUE);
    else paymentStatuses.push(PAYMENT_STATUS.UNPAID);
  }
  return paymentStatuses;
};

const sumAmounts = (amounts) => {
  return amounts.reduce((acc, obj) => acc + obj.amount, 0);
};

const calcOverdue = (reconciliationsBySupplier, supplierId, id) => {
  const indexOfCurrentReconciliation = reconciliationsBySupplier[supplierId].findIndex((r) => r.id === id);

  if (!(supplierId in reconciliationsBySupplier) || indexOfCurrentReconciliation === -1) return [];

  const overdue = reconciliationsBySupplier[supplierId]
    .slice(0, indexOfCurrentReconciliation)
    .filter((r) => r.paymentStatuses.includes(PAYMENT_STATUS.OVERDUE))
    .map(({ totalBilledAmount, totalPaidAmount, paymentDueDate }) => ({
      totalBilledAmount,
      paymentDueDate,
      totalUnpaidAmount: totalBilledAmount - totalPaidAmount,
    }));
  if (Array.isArray(overdue)) return overdue;
  return [];
};

const createReconciliationsBySupplier = (reconciliations) => {
  const reconciliationsBySupplier = reconciliations.reduce((reconciliationsMap, imbalance) => {
    if (reconciliationsMap[imbalance.supplierId]) reconciliationsMap[imbalance.supplierId].push(imbalance);
    else reconciliationsMap[imbalance.supplierId] = [imbalance];
    return reconciliationsMap;
  }, {});

  Object.entries(reconciliationsBySupplier).forEach(([supplierId, relatedReconciliation]) => {
    reconciliationsBySupplier[supplierId] = relatedReconciliation.sort(
      (a, b) => DateTime.fromISO(a.paymentDueDate) - DateTime.fromISO(b.paymentDueDate)
    );
  });

  return reconciliationsBySupplier;
};

export const getLastReport = (docs) => {
  const lastReport = {};
  if (docs.length) {
    lastReport.reconciliationDocument = docs[0];
    lastReport.type = docs[0].type;
  }
  return isEmpty(lastReport) ? null : lastReport;
};
