import { gql } from '@apollo/client/core';
import { computed } from 'vue';
import { useQuery } from '@vue/apollo-composable';

import { useNotification, useLoading } from '@/modules/core';

export function useTasks(variables) {
  const { error } = useNotification();

  const { result, loading: queryLoading, refetch, onError } = useQuery(TASKS_QUERY, variables);

  const totalCount = computed(() => result.value?.tasksNew2?.totalCount ?? 0);
  const tasks = computed(() => result.value?.tasksNew2?.nodes ?? []);
  const loading = useLoading(queryLoading, variables);

  onError((param) => {
    console.error('activity - useTasks', param);
    error();
  });

  return {
    tasks,
    refetch,
    totalCount,
    loading,
  };
}

const TASKS_QUERY = gql`
  query tasks($businessId: ID!, $limit: Int, $offset: Int, $completed: Boolean, $domain: String, $type: String) {
    tasksNew2(
      businessId: $businessId
      limit: $limit
      offset: $offset
      completed: $completed
      domain: $domain
      type: $type
    ) {
      totalCount
      nodes {
        id
        createdAt
        completedAt
        activeAt
        activeBy: activeByNew {
          firstName: given_name
          lastName: family_name
        }
        completedBy: completedByNew {
          firstName: given_name
          lastName: family_name
        }
        data
      }
    }
  }
`;
