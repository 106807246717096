import { computed } from 'vue';
import { gql } from '@apollo/client/core';
import { useQuery, useResult } from '@vue/apollo-composable';

import { useLoading, useNotification } from '@/modules/core';

export function useTerms(variables) {
  const { error } = useNotification();

  const { result, loading: queryLoading, onError, refetch } = useQuery(TERMS_QUERY, variables);

  const terms = useResult(result, []);

  onError((err) => {
    console.error('Operation management - useTerms', err);
    error();
  });

  const loading = useLoading(queryLoading, variables);

  return {
    terms,
    loading,
    refetch,
  };
}

export function useTermsNew(variables) {
  const { error } = useNotification();

  const { result, loading: queryLoading, onError, refetch } = useQuery(TERMS_NEW_QUERY, variables);

  const terms = computed(() => (result.value ? result.value.termsNew?.nodes ?? [] : []));

  onError((err) => {
    console.error('Operation management - useTermsNew', err);
    error();
  });

  const loading = useLoading(queryLoading, variables);

  return {
    terms,
    loading,
    refetch,
  };
}

export const TERMS_NEW_QUERY = gql`
  query terms($businessId: ID) {
    termsNew(type: payment, businessId: $businessId) {
      nodes {
        id
        supplierId
        paymentMethod
        paymentDueNet
        directDebit
        fromDate
        type: typeNew
        eom
        bankAccountId
      }
    }
  }
`;

export const TERMS_QUERY = gql`
  query terms($businessId: ID) {
    terms(type: payment, businessId: $businessId) {
      id
      supplierId
      paymentMethod
      paymentDueNet
      directDebit
      fromDate
      type: typeNew
      eom
      bankAccountId
    }
  }
`;
