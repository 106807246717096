import { computed } from 'vue';
import { isEmpty } from 'ramda';
import { gql } from '@apollo/client/core';
import { useQuery } from '@vue/apollo-composable';

import { useNotification } from '@/modules/core';

export function useReconciliations(variables) {
  const { error } = useNotification();

  const { result, loading, onError, refetch } = useQuery(RECONCILIATIONS_QUERY, variables, () => ({
    enabled: !!variables.value.businessId && !!variables.value.ids && !isEmpty(variables.value.ids),
  }));

  const reconciliations = computed(() => result?.value?.reconciliations.nodes ?? []);

  onError((err) => {
    console.error('useReconciliations', err);
    error();
  });

  return {
    reconciliations,
    loading,
    refetch,
  };
}

export const RECONCILIATIONS_QUERY = gql`
  query reconciliations($businessId: ID!, $ids: [String]) {
    reconciliations(businessId: $businessId, ids: $ids) {
      nodes {
        id
        paymentDueDate
        paymentTermId
        supplierId
        periodStart
        periodEnd
        closed
        status {
          status
          details {
            key
            status
            details {
              key
              status
            }
          }
        }
        statusOverride {
          status
          comment
          modifiedAt
          modifiedByDetails {
            firstName
            lastName
          }
        }
      }
    }
  }
`;
