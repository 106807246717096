<template>
  <div>
    <h3 class="mb-4">{{ title }}</h3>
    <Table
      v-loading="tasksLoading || tasksUpdating"
      :columns="columns"
      :data="tableData"
      border
      rounded
      hover
      custom-class="root-table"
      @row-click="handleRowClick($event)"
    >
      <template #cell-index="{ rowIndex }">
        {{ rowIndex + 1 + TASKS_DEFAULT_LIMIT * (currentPage - 1) }}
      </template>

      <template #[`cell-${TABLE_HEADER.TASK_STATUS}`]="{ rowData: { task } }">
        <div class="d-flex">
          <TaskTag :task="task" @updateTask="handleTaskUpdate">
            <template #task-snooze-modal-subtitle>
              <RestaurantSupplierSubtitle
                :supplier-name="getSupplierName(task.data.supplierId)"
                :customer-name="currentTenant.name"
              />
            </template>
          </TaskTag>
          <TruncatedText class="w-75 px-2">
            {{ task.data.comment }}
          </TruncatedText>
        </div>
      </template>
      <template #[`cell-${TABLE_HEADER.SUPPLIER}`]="{ rowData: { task } }">
        <div>
          <TruncatedText>
            {{ getSupplierName(task.data.supplierId) }}
          </TruncatedText>
        </div>
      </template>
    </Table>
    <div class="d-flex justify-content-end my-3">
      <el-pagination
        v-if="tasksTotalCount > TASKS_DEFAULT_LIMIT"
        small
        layout="prev, pager, next, jumper"
        background
        :current-page.sync="currentPage"
        :page-size="TASKS_DEFAULT_LIMIT"
        :page-count="Math.ceil(tasksTotalCount / TASKS_DEFAULT_LIMIT)"
        :total="tasksTotalCount"
      />
    </div>
  </div>
</template>

<script>
import { isNil } from 'ramda';
import { ref, computed } from 'vue';

import { RestaurantSupplierSubtitle, usePatchTask, useActivateTask } from '@/modules/tasks';
import { Table, TruncatedText } from '@/modules/core';
import { TaskTag } from '@/modules/tasks/taskTag';
import { useSuppliersNew } from '@/modules/suppliers';
import { useTenancy, useUser } from '@/modules/auth';

import { useTasks } from './composition';

const TABLE_HEADER = {
  TASK_STATUS: 'taskStatus',
  SUPPLIER: 'supplier',
  BILLING_PERIOD: 'billingPeriod',
  PAYMENT_DUE_DATE: 'paymentDueDate',
  RECONCILIATION_STATUS: 'reconciliationStatus',
  ACTIONS: 'actions',
};

const TASKS_DEFAULT_LIMIT = 25;

export default {
  components: {
    Table,
    TaskTag,
    RestaurantSupplierSubtitle,
    TruncatedText,
  },
  props: {
    supplierId: {
      type: String,
      default: () => '',
    },
    title: {
      type: String,
      default: () => '',
    },
  },

  setup() {
    const currentPage = ref(1);
    const { currentTenant } = useTenancy();
    const { isAdmin } = useUser();

    const { suppliers } = useSuppliersNew();

    const pagination = computed(() => ({
      limit: TASKS_DEFAULT_LIMIT,
      offset: (currentPage.value - 1) * TASKS_DEFAULT_LIMIT,
    }));

    const { patchTask } = usePatchTask();
    const { activeTask } = useActivateTask();

    const {
      tasks: tasks,
      totalCount: tasksTotalCount,
      loading: tasksLoading,
      refetch: tasksRefetch,
    } = useTasks(() => ({
      businessId: currentTenant.value.id,
      completed: false,
      domain: 'billing',
      type: 'uncertainBilling',
      ...pagination.value,
    }));

    return {
      tasksUpdating: ref(false),
      isAdmin,
      suppliers,
      TABLE_HEADER,
      tasks,
      tasksTotalCount,
      currentTenant,
      tasksLoading,
      tasksRefetch,
      patchTask,
      activeTask,
      currentPage,
      TASKS_DEFAULT_LIMIT,
    };
  },
  computed: {
    tableData() {
      return (this.tasks ?? []).map((task) => ({
        task,
      }));
    },
    columns() {
      return [
        {
          key: 'index',
          width: '20px',
        },
        {
          header: this.$t('operationManagement.uncertainBillingTable.table.headers.taskStatus'),
          key: TABLE_HEADER.TASK_STATUS,
        },
        {
          header: this.$t('operationManagement.uncertainBillingTable.table.headers.supplier'),
          key: TABLE_HEADER.SUPPLIER,
        },
      ];
    },
  },
  methods: {
    getSupplierName(supplierId) {
      return this.suppliers.find(({ id }) => id === supplierId)?.name ?? '';
    },
    async handleTaskUpdate(updateData) {
      try {
        this.tasksUpdating = true;
        await this.patchTask({
          taskId: updateData.task.id,
          patchParams: {
            data: { ...updateData.task.data, comment: updateData.comment },
          },
        });
        if (!isNil(updateData.activeAt)) {
          await this.activeTask({
            taskId: updateData.task.id,
            activeParams: {
              activeAt: updateData.activeAt,
            },
          });
        }
        await this.tasksRefetch();
      } finally {
        this.tasksUpdating = false;
      }
    },
    handleRowClick(rowIndex) {
      this.$router.push({ name: 'uncertainBillingTask', params: { taskId: this.tableData[rowIndex].task.id } });
    },
  },
};
</script>
